import { graphql } from "gatsby"
import React from "react"

import {
  FaFacebook,
  FaInstagram,
  FaBehance,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa"

import Layout from "../layouts/Layout"
import PageFooter from "../components/PageFooter"
import SiteMetadata from "../components/SiteMetadata"

const ContactPage = ({ data }) => {
  // trigger redeploy.....................................................
  const iconClassName =
    "w-10 h-10 inline-block mr-3 hover:text-black duration-300 transition-all ease-in-out"

  return (
    <Layout>
      <SiteMetadata
        title="Pri Bellafronte - Designer de marcas e logos | Contato"
        description="Página com informações para entrar contato com a designer gráfico Pri Bellafronte"
      />

      <div>
        <div className="container py-12 lg:pb-16">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 xl:w-1/4 pb-8 md:pb-0">
              <h2 className="text-xl leading-tight font-bold tracking-tight text-gray-900 sm:text-2xl">
                Redes sociais
              </h2>
              <div className="mt-5">
                <a
                  href="https://www.facebook.com/pribellafronte"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook className={iconClassName + " text-blue-700"} />
                </a>

                <a
                  href="https://www.behance.net/Pribellafonte"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaBehance className={iconClassName + " text-indigo-600"} />
                </a>

                <a
                  href="https://www.instagram.com/pribellafrontedesigner/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className={iconClassName + " text-red-400"} />
                </a>

                <a
                  href="https://www.linkedin.com/in/priscilla-bellafronte-ba1903b7/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin className={iconClassName + " text-blue-600"} />
                </a>
              </div>

              <h2 className="mt-12 text-xl leading-tight font-bold tracking-tight text-gray-900 sm:text-2xl">
                Fale por Whatsapp
              </h2>
              <div className="mt-5">
                <a
                  href="https://api.whatsapp.com/send?phone=5521998213067&text=&source=&data=#"
                  class="bg-green-600 hover:bg-green-700 duration-300 transition-all ease-in-out text-gray-100 font-bold py-2 px-4 rounded inline-flex items-center"
                >
                  <FaWhatsapp />
                  <span className="ml-2 inline-block">Enviar uma mensagem</span>
                </a>
              </div>
            </div>

            <div className="w-full md:w-1/2 xl:w-3/4 md:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Entre em contato
              </h1>

              <div className="mt-4 leading-loose">
                Pode ser para pedir orçamento, uma ajudinha, tirar dúvidas. O
                que eu puder ajudar, é só falar.
                <br />
                <br />
                Você pode me enviar um email através do formulário abaixo ou
                através do meu endereço de email, que é&nbsp;
                <a
                  href="mailto:contato@pribellafronte.com.br"
                  className="text-blue underline"
                >
                  contato@pribellafronte.com.br
                </a>
                . Da forma que preferir 🙂
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 px-4 lg:py-16">
        <PageFooter />
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  {
    author: file(relativePath: { eq: "pri-sobre-mim.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 480
          quality: 85
          layout: CONSTRAINED
        )
      }
    }
  }
`
